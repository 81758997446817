import {useObservable} from '@mindspace-io/react';
import {companyFacade} from '../../../application/company/facade/company.fecade';
import {CompanyHookType} from '../../../Domain/interface/company/company.hook.type';
import {ID} from '@datorama/akita';
import {Company} from '../../../Domain/interface/company/company';
import {Media} from '../../../types/media';


export function useCompany(): [CompanyHookType] {
  const [company] = useObservable(companyFacade.company$, []);
  const [activeCompany] = useObservable(companyFacade.activeCompany$);
  const [loading] = useObservable(companyFacade.loading$);

  const getCompany = (): void => companyFacade.getCompany();
  const saveCompany =(id: ID, infoCompany: Company): void => companyFacade.saveCompany(id, infoCompany);
  const saveEvidenceCompany = (id: ID, infoCompany: Media[]): void => companyFacade
      .saveEvidenceCompany(id, infoCompany);
  const getDocumentCompany = (id: ID, detail?: boolean): void => companyFacade.getDocumentCompany(id, detail);
  const removeDocumentCompany = (id: ID, documentId: number): void => companyFacade
      .removeDocumentCompany(id, documentId);
  return [
    {company,
      activeCompany,
      loading,
      getCompany,
      saveCompany,
      saveEvidenceCompany,
      getDocumentCompany,
      removeDocumentCompany,
    },
  ];
}
