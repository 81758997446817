/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define */
import React, {useEffect, useState} from 'react';
import {InherentImpact} from '../../../../types/InherentImpact';
import {IonToast} from '@ionic/react';
import './Register.scss';
import {Category} from '../../../../types/category';
import {PossibleEffects} from '../../../../types/possibleEffect';
import {getStorage} from '../../../../helpers/localstorageUtil';
import {
  LONG_TIME,
  RISK_FORM_REG_EXPR,
  RISK_URL_CATEGORIES,
  RISK_URL_INHERENT_IMPACT,
  RISK_URL_POSSIBLE_EFFECT,
  RISK_URL_RISKS,
  STANDARD_TIME,
  SUCCESS_DATA_SAVED,
  SUCCESS_DATA_UPDATED, whatever,
} from '../../../../constants';
import {Risk, ToRegisterRisk} from '../../../../types/risk';
import {RouteComponentProps} from 'react-router';
import {navigateToPath} from '../../../../helpers/navigationUtil';
import {MediaType} from '../../../../types/enums/mediaType';
import {RiskMode} from '../../../../types/enums/riskMode';
import {useRisk} from '../../../../akita/hooks/risk.hooks';
import RiskFor from './RiskForm';
import {ID} from '@datorama/akita';
import {useCompany} from '../../../../infrastructure/company/hook/company.hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Prop extends RouteComponentProps, ToRegisterRisk {}

const RiskToast: React.FC<Prop> = (props): whatever=> {
  const [state, setState] = useState<ToRegisterRisk>({id: 0,
    description: '',
    category: 0,
    inherentChance: 0,
    inherentImpact: 0,
    netChance: 0,
    netImpact: 0,
    possibleEffects: [],
    toast: false,
    toastMessage: '',
    toastTime: STANDARD_TIME,
    arrCategory: [],
    healtImpact: [],
    possibleEffect: [],
    reqDescription: false,
    reqChance: false,
    reqCategory: false,
    reqImpact: false,
    reqEffect: false,
    photo: '',
    mode: ''});

  const [{risks, activeRisk, updateActive, postGeneral: postAkitaRisk,
    updateRisk: updateAkitarisk, addPhotos: addAkitaPohtos}] = useRisk();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [{getCompany: getCompanyAkita, company}] = useCompany();

  const fillCategory = (id: number): string => {
    let response = '';
    const arrCategory: Category[] = state.arrCategory;
    try {
      for (const cat of arrCategory) {
        if (cat.id === id) {
          response = JSON.stringify(cat);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as Error).message);
    }
    return response;
  };

  const convertStringFromNum =(data: Risk): whatever => {
    const possibleEffects = (data.possibleEffects as []).map((effect: string)=> effect);
    const resData = {
      ...data, id: data.id,
      category: data.category,
      possibleEffects,
      controls: [],
    };
    return resData;
  };

  useEffect(() => {
    if (loading) {
      if (activeRisk && activeRisk.id) {
        // getAkitaRisk(Number(activeRisk.id));
        const res = convertStringFromNum(activeRisk);
        if (state.photo) {
          addAkitaPohtos(res.id as ID, [{base64: state.photo, mediaType: MediaType.IMAGE}]);
        }

        if (activeRisk.mode === RiskMode.REPORT) {
          navigateToPath('/tab9', props, {dataDetail: res, flag: 'riskDetail'}, true);
          setLoading(false);
          resetForm();
        }

        if (activeRisk.mode === RiskMode.EVALUATION) {
          navigateToPath('/tab9', props, {dataDetail: res, flag: 'riskDetail'}, true);
          setLoading(false);
          resetForm();
        }
      }
    }
    // eslint-disable-next-line
  }, [activeRisk]);

  useEffect(() => {
    if (loadingUpdate) {
      const r: whatever = props.history.location.state;
      const riskLocalStorage = JSON.parse(localStorage.getItem('sel-risk') as string);
      const risk = convertStringFromNum(riskLocalStorage);
      // getAkitaRisk(r.id);
      if (risk && risk.id === r.id) {
        if (JSON.stringify(risk) !== JSON.stringify(r) ) {
          navigateToPath('/tab9', props, {dataDetail: risk, flag: 'riskDetail'}, true);
          setLoadingUpdate(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [risks, loadingUpdate]);

  useEffect(() => {
    if (isLoading) {
      getCompanyAkita();


      categories();
      inherentImpact();
      possibleEffect();

      if (props.history.location.state) {
        const aux = props.history.location.state as whatever;
        try {
          setState((state: whatever) => ({
            ...state,
            id: aux.id,
            category: aux.category + '',
            description: aux.description,
            inherentImpact: String(aux.inherentImpact),
            inherentChance: aux.inherentChance,
            netImpact: String(aux.netImpact),
            netChance: aux.netChance,
            possibleEffects: aux.possibleEffects.map((x: number) => x.toString()),
          }));
        } catch (e) {
        }
      } else {
        resetForm();
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [props.history.location.state]);

  useEffect(()=> {
    if (company.length) setState((state: whatever) => ({...state, mode: company.data}));
    // eslint-disable-next-line no-console
  }, [company]);

  const showRequired = (key: string): void => {
    switch (key) {
      case 'category':
        setState({...state, reqCategory: true});
        break;
      case 'description':
        setState({...state, reqDescription: true});
        break;
      case 'inherentImpact':
        setState({...state, reqImpact: true});
        break;
      case 'inherentChance':
        setState({...state, reqChance: true});
        break;
      case 'possibleEffects':
        setState({...state, reqEffect: true});
        break;
    }
  };

  const handleForm = (event: whatever): void => {
    event.persist();
    event.preventDefault();
    if (!checkFields()) {
      saveAkitaRisk();
    }
  };


  const checkFields = (): boolean => {
    let response = false;
    const values = Object.values(state as whatever);
    const keys = Object.keys(state);
    for (let i = 0; i < keys.length; i++) {
      if (new RegExp(RISK_FORM_REG_EXPR).test(keys[i]) && !values[i]) {
        showRequired(keys[i]);
        response = true;
      }
    }
    return response;
  };


  const parseSave = (value: Risk): Risk => {
    return {
      id: props.history.location.state ? (props.history.location.state as whatever).id : 0,
      description: value.description,
      category: value.category,
      inherentChance: value.inherentChance,
      inherentImpact: value.inherentImpact,
      netImpact: value.netImpact ? value.netImpact : value.inherentImpact,
      netChance: value.netChance ? value.netChance : value.inherentChance,
      possibleEffects: value.possibleEffects,
      mode: value.mode ? value.mode : 'REPORT',
    };
  };
  const categories = async (): Promise<void> => {
    const cat = await getStorage<Category[]>(RISK_URL_CATEGORIES);
    if (cat) setState((state: whatever) => ({...state, arrCategory: cat}));
  };


  const inherentImpact = async (): Promise<void> => {
    const health = await getStorage<InherentImpact[]>(RISK_URL_INHERENT_IMPACT);
    if (health) setState((state: whatever) => ({...state, healtImpact: health}));
  };

  const possibleEffect = async (): Promise<whatever> => {
    const posEffect = await getStorage<PossibleEffects[]>(RISK_URL_POSSIBLE_EFFECT);

    if (posEffect) setState((state: whatever) => ({...state, possibleEffect: posEffect}));
    return posEffect;
  };

  const saveAkitaRisk = (): void => {
    const resp: whatever = props.history.location.state;
    // eslint-disable-next-line no-console
    /* let data = JSON.parse(localStorage.getItem('risk_data') as string);
    data.id = parseInt(data.id);
    data = {...data, state};
    localStorage.setItem('risk_data', JSON.stringify(data)); */

    if (resp) {
      updateAkitarisk(parseSave(state), state.id as ID);
      localStorage.setItem('sel-risk', JSON.stringify(parseSave(state)));
      updateActive(parseSave(state));
      setLoading(false);
    } else {
      postAkitaRisk(RISK_URL_RISKS, parseSave(state));
    }

    try {
      if (resp === undefined) {
        setState({...state, toast: true, toastMessage: SUCCESS_DATA_SAVED, toastTime: LONG_TIME});
        setLoading(true);
      } else if (resp.id) {
        setState({...state, toast: true, toastMessage: SUCCESS_DATA_UPDATED, toastTime: LONG_TIME});
        setLoading(false);
        setLoadingUpdate(true);
      }
    } catch (error) {
      setState({...state, toast: true, toastMessage: (error as Error).message, toastTime: LONG_TIME});
    }
  };


  const resetForm = (): void => {
    try {
      setState((state: whatever) => ({...state,
        id: 0,
        description: '',
        category: 0,
        inherentChance: 0,
        inherentImpact: 0,
        possibleEffects: [],
        reqDescription: false,
        reqChance: false,
        reqCategory: false,
        reqImpact: false,
        reqEffect: false,
        photo: '',
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as Error).message);
    }
  };


  return (
    <>
      <IonToast
        isOpen={state.toast}
        onDidDismiss={(): void => setState({...state, toast: false})}
        message={state.toastMessage}
        duration={state.toastTime}
      />

      <RiskFor
        state={state}
        handleForm={handleForm}
        setState={setState}
        localName={props.history.location.state} properties={props}
      />
    </>
  );
};


export default RiskToast;
