import {IonSelectOption} from '@ionic/react';
import React from 'react';
function randomKey<T, K extends keyof T>(enumClass: T, key: K) {
  return (
    <IonSelectOption key={key as string} value={key}>
      {enumClass[key] as React.ReactNode}
    </IonSelectOption>
  );
}

export function createIonSelectOptionWithEnum<T>(enumClass: T) {
  // @ts-expect-error np other way to resolve for now
  return Object.keys(enumClass).map((key) => randomKey(enumClass, key as keyof T));
}
