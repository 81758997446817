import React from 'react';
import {IonButton, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar} from '@ionic/react';
import {whatever} from '../../../constants';
import './alertModal.scss';
import {closeCircle} from 'ionicons/icons';

interface Prop {
  isOpen: boolean;
  title: string;
  dissmiss: (value: boolean) => void;
  ModalContent: whatever;
}

export const AlertModal: React.FC<Prop> = ({isOpen, dissmiss, ModalContent, title}) => {
  return (
    <>
      <IonModal {...{cssClass: 'general-modal'}} isOpen={isOpen} onDidDismiss={(): void => dissmiss(false)}>
        <IonHeader translucent>
          <IonToolbar className="header-modal">
            <IonTitle className="modal-title">{title}</IonTitle>
            <IonButton className="modal-btn" slot="end" onClick={(): void => dissmiss(false)}>
              <IonIcon className="icon-btn" icon={closeCircle}/>
            </IonButton>
          </IonToolbar>
        </IonHeader>
        {isOpen ? <IonContent className="general-modal">{ModalContent}</IonContent> : null}
      </IonModal>
    </>
  );
};
