import {useObservable} from '@mindspace-io/react';
import {controlFacade} from '../facade/control.facade';
import {Control} from '../../types/control';
import {ControlHookType} from './types/control.hook.type';
import {ID} from '@datorama/akita';
import {Epp} from '../../types/epp';

export function useControl(): [ControlHookType] {
  const [control] = useObservable(controlFacade.control$, []);
  const [activeControl] = useObservable(controlFacade.activeControl$);
  const [loading] = useObservable(controlFacade.loading$);

  const getControl = (): void => controlFacade.getControl();
  const saveControl = (dataControl: Control): void => controlFacade.saveControl(dataControl);
  const getControlById = (controlId: ID): void => controlFacade.getControlById(controlId);
  const updateControl = (data: Control ): void => controlFacade.updateControl(data);
  const deleteControl= (controlId: ID): void => controlFacade.deleteControl(controlId);
  const getControlRisks= (controlId: ID): void => controlFacade.getControlRisks(controlId);
  const getControlTypes= (storage?: boolean): void => controlFacade.getControlTypes(storage);
  const killSubscription = (): void => controlFacade.killSubscriptions();
  const setActiveControl = (controlId: ID): void => controlFacade.setActive(controlId);
  const saveEpp = (controlId: Control, data: Epp): void => controlFacade.saveEpp(controlId, data);
  const setLoading = (flag: boolean): void => controlFacade.setLoading(flag);
  return [
    {control,
      activeControl,
      getControl,
      saveControl,
      getControlById,
      updateControl,
      deleteControl,
      getControlRisks,
      getControlTypes,
      killSubscription,
      setActiveControl,
      saveEpp,
      loading,
      setLoading,
    },
  ];
}
