import React from 'react';
import {notLoggedIn} from '../../helpers/service';
import {clearListStorage, setStorage} from '../../helpers/localstorageUtil';
import {
  getUpdatePreferenceProfile,
  getWithRights,
  saveAuthorization,
} from '../../services/userAuthorization.service';
import {getAdmin, setAdmin} from '../../services/general.service';
import {Redirect} from 'react-router-dom';
import {Plugins} from '@capacitor/core';
import Landing from './UtilComponents/Landing';


// CAPACITOR
const splashScreen = Plugins.SplashScreen;
type Props = {
    children: JSX.Element;
};

const LoginValidation = (props: Props): JSX.Element => {
  if (notLoggedIn()) {
    clearListStorage();
    return <Landing />;
  } else if (!notLoggedIn()) {
    setStorage('loaded', true);
    getUpdatePreferenceProfile().subscribe((res) => {
      try {
        if (res && res.status === 200 && res.data) {
          if (!res.data.auth && !getAdmin()) {
            localStorage.setItem('auth', 'false');
            if (!window.location.hash.includes('/tab14')) return <Redirect to='/tab14'/>;
          }
        }
      } catch {
        splashScreen.hide();
        return <><h1>ocurrió un error</h1></>;
      }
    });
    const right = getWithRights();
    const admin = getAdmin();
    try {
      if (right) {
        saveAuthorization().then(() => !window.location.hash.includes('/tab14') &&
            <Redirect to='/tab14'/>);
      }

      if (admin) setAdmin();

      splashScreen.hide();
    } catch {
      // eslint-disable-next-line no-console
      splashScreen.hide();
      return <><h1>ocurrió un error</h1></>;
    }
  }
  return (props.children);
};

export default LoginValidation;
