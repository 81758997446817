import React, {Dispatch, SetStateAction, useState} from 'react';
import {ANIMATION_FADE_IN_DOWN, EDIT_RISK, REGISTER_RISK, whatever} from '../../../../constants';
import {
  IonCol,
  IonContent, IonFab, IonFabButton,
  IonGrid,
  IonHeader, IonIcon, IonInput, IonItem, IonLabel,
  IonRippleEffect,
  IonRow, IonSelect, IonSelectOption,
  IonTitle,
  IonToolbar,
  IonCardContent, IonButton,
} from '@ionic/react';
import {RouteComponentProps} from 'react-router';
import {ToRegisterRisk} from '../../../../types/risk';
import {Category} from '../../../../types/category';
import {InherentImpact} from '../../../../types/InherentImpact';
import PosibleEffectMultiselect from '../../Control/PosibleEffectMultiSelect';
import {AppToolbar} from '../../../dummies/UtilComponents/toolbar/appToolbar';
import {RiskMode} from '../../../../types/enums/riskMode';
import {createIonSelectOptionWithEnum} from '../../../dummies/UtilComponents/enumUtil';
import {PossibilityOfOccurrance} from '../../../../types/enums/possibilityOfOccurrance';
import {Save} from '../../../dummies/Button-Options/UtilsButton';
import {camera} from 'ionicons/icons';
import {Plugins} from '@capacitor/core';
import {CameraResultType} from '@capacitor/camera';
import RiskFormImage from '../../../dummies/RiskFormImage/RiskFormImage';
import './RiskForm.scss';

type Prop = {
  properties: RouteComponentProps;
  state: ToRegisterRisk;
  handleForm: (e: whatever) => void;
  setState: Dispatch<SetStateAction<ToRegisterRisk>>;
  localName: whatever;
}

const RiskFor: React.FC<Prop> = ({localName,
  properties: props, state, setState, handleForm}): whatever => {
  const [onPic, setOnPic] = useState(true);
  const makePhoto = async (): Promise<void> => {
    const {Camera} = Plugins;
    const image = await Camera.getPhoto({
      quality: 80,
      resultType: CameraResultType.DataUrl,
      height: 640,
      width: 480,
    });
    setState({...state, photo: image.dataUrl as string});
    setOnPic(true);
  };

  const updateMod = (): void => {
    if (state.mode === RiskMode.EVALUATION) setState({...state, mode: RiskMode.REPORT});
    else setState({...state, mode: RiskMode.EVALUATION});
  };

  const deleteImage = (): void => {
    state = {...state, photo: undefined};
    setOnPic(false);
  };

  const handlePosibleEfectChange = (event: whatever[]): void => {
    setState({...state, possibleEffects: event.map((x) => x.id)});
  };

  const handleChange =(event: whatever): void => {
    setState({...state, [event.target.name]: event.detail.value});
  };

  if (localName && state.id !== localName.id ) setState({...state, ...localName});

  return ( <>
    <IonHeader className="headerTitle">
      {localName ? (
                <AppToolbar
                  title={EDIT_RISK}
                  navigation={{
                    defHref: '/tab4',
                    props,
                    data: {dataDetail: localName, flag: 'riskDetail'},
                    url: '/tab9',
                  }}
                />
            ) : (<AppToolbar title={REGISTER_RISK}/>)}
    </IonHeader>
    <IonContent style={{height: '93%', overflow: 'auto'}}>
      <div>
        {onPic && state.photo ? <RiskFormImage picture={state.photo} removeImage={deleteImage}/> : ''}
      </div>
      {!localName && !state.photo ? <IonItem lines="none"/> : null}
      <IonCardContent className={'ion-content-card'}>
        {!localName ? (
              <IonButton
                // eslint-disable-next-line
                className={ANIMATION_FADE_IN_DOWN + ' ' +'report-btn'}
                color={state.mode === RiskMode.EVALUATION ? 'primary' : 'danger'}
                onClick={updateMod}
                mode="md"
              >
                <div className="ion-activatable">
                  <IonRippleEffect/>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonTitle style={{textTransform: 'none', fontSize: '14px'}}>
                          Modo {state.mode === RiskMode.EVALUATION ? 'evaluacion' : 'reportar'}
                        </IonTitle>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </IonButton>
          ) : null}
        {!localName ? (
              <IonFab vertical="top" horizontal="end" style={{marginRight: '19px'}}>
                <IonFabButton size="small" onClick={makePhoto}>
                  <IonIcon icon={camera}/>
                </IonFabButton>
              </IonFab>
          ) : null}
        <form onSubmit={handleForm}>
          <IonItem className={'ionic-item'} lines={'none'}>
            <IonLabel className={'ionic-label'} position="stacked">Descripción</IonLabel>
            <IonInput
              className={'ionic-input'}
              required
              placeholder="Describa el riesgo"
              value={state.description}
              name={'description'}
              onIonChange={handleChange}
            />
          </IonItem>
          {
            <IonItem className={'ionic-item'} lines={'none'}>
              <IonLabel className={'ionic-label'} position="floating">Categoría</IonLabel>
              <IonSelect
                className={'ionic-input ionic-dataTime-padding'}
                placeholder="Seleccione"
                interface="action-sheet"
                value={state.category}
                name={'category'}
                onIonChange={handleChange}
                cancelText="Cancelar"
              >
                { state.arrCategory.length > 0 ?
                  state.arrCategory.map((cat: Category, i: number) => (
                    <IonSelectOption key={i} value={cat.id + ''}>
                      {cat.title}
                    </IonSelectOption>
                  )): null }
              </IonSelect>
              {state.reqCategory ? <span style={{color: 'red'}}>Falta categoría</span> : ''}
            </IonItem>
          }
          <IonItem className={'ionic-item'} lines={'none'} >
            <PosibleEffectMultiselect
              labelInput="Posibles efectos"
              title="Posibles efectos"
              item={state.possibleEffect}
              selected={state.possibleEffects as string[]}
              changeValue={handlePosibleEfectChange}
            />
          </IonItem>
          {/* INHERENT*/}
          {localName ? (
                    <IonToolbar color="primary">
                      <IonTitle>Inherente</IonTitle>
                    </IonToolbar>
                ) : null}
          <IonItem className={'ionic-item'} lines={'none'}>
            <IonLabel className={'ionic-label'} position="stacked">Posibilidad de ocurrir</IonLabel>
            <IonSelect
              className={'ionic-input ionic-dataTime-padding'}
              placeholder="Seleccione"
              interface="action-sheet"
              value={state.inherentChance}
              name={'inherentChance'}
              onIonChange={handleChange}
              cancelText="Cancelar"
            >
              {createIonSelectOptionWithEnum(PossibilityOfOccurrance)}
            </IonSelect>
            {state.reqChance ? <span style={{color: 'red'}}>Falta posibilidad de ocurrir</span> : ''}
          </IonItem>
          <IonItem className={'ionic-item'} lines={'none'}>
            <IonLabel className={'ionic-label'} position="stacked">Impacto en salud</IonLabel>
            <IonSelect
              className={'ionic-input ionic-dataTime-padding'}
              placeholder="Seleccione"
              interface="action-sheet"
              value={state.inherentImpact}
              name={'inherentImpact'}
              onIonChange={handleChange}
              cancelText="Cancelar"
            >
              {state.healtImpact.length ?
                        state.healtImpact.map((health: InherentImpact, i: number) => (
                          <IonSelectOption key={i} value={health.chanceId}>
                            {health.description}
                          </IonSelectOption>
                        )) :
                        null}
            </IonSelect>
            {state.reqImpact ? <span style={{color: 'red'}}>Falta impacto en salud</span> : ''}
          </IonItem>
          {/* NET */}
          {localName ? (
                    <>
                      <IonToolbar color="primary">
                        <IonTitle>Neto</IonTitle>
                      </IonToolbar>
                      <br/>
                      <IonItem className={'ionic-item'} lines={'none'}>
                        <IonLabel className={'ionic-label'} position="stacked">Posibilidad de ocurrir (Neto)</IonLabel>
                        <IonSelect
                          className={'ionic-input ionic-dataTime-padding'}
                          placeholder="Seleccione"
                          interface="action-sheet"
                          value={state.netChance}
                          name={'netChance'}
                          onIonChange={handleChange}
                        >
                          {createIonSelectOptionWithEnum(PossibilityOfOccurrance)}
                        </IonSelect>
                        {state.reqChance ? (<span style={{color: 'red'}}>Falta posibilidad de ocurrir (Neto)</span>) : (
                            '')}
                      </IonItem>
                      <IonItem className={'ionic-item'} lines={'none'}>
                        <IonLabel className={'ionic-label'} position="stacked">Impacto en salud (Neto)</IonLabel>
                        <IonSelect
                          className={'ionic-input ionic-dataTime-padding'}
                          placeholder="Seleccione"
                          interface="action-sheet"
                          value={state.netImpact}
                          name={'netImpact'}
                          onIonChange={handleChange}
                        >
                          {state.healtImpact.length ?
                              state.healtImpact.map((health: InherentImpact, i: number) => (
                                <IonSelectOption key={i} value={health.chanceId + ''}>
                                  {health.description}
                                </IonSelectOption>
                              )) :
                              null}
                        </IonSelect>
                        {state.reqImpact ? <span style={{color: 'red'}}>Falta impacto en salud (Neto)</span> : ''}
                      </IonItem>
                    </>
                ) : null}
          <Save name={'Guardar'}/>
        </form>
      </IonCardContent>
    </IonContent>
  </>
  );
};

export default RiskFor;
