import React, {useContext, useEffect, useState} from 'react';
import {
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonBadge,
  IonChip, IonButton, IonAvatar, IonImg,
} from '@ionic/react';
import {notifications, arrowBack, checkmarkCircleOutline} from 'ionicons/icons';
import {RouteComponentProps, useHistory} from 'react-router';
import {AlertModal} from '../alertModal';
import {notificationTestList} from '../../../smarts/risk-components/Notifications/TestNotificaition';
import NotificatonList from '../../../smarts/risk-components/Notifications/notificationList';
import {notificationContext} from '../../../App';
import {getObject, setObject} from '../../../../helpers/storageController';
import {Notifications} from '../../../../types/notifications';
import EmptyDataIcon from '../InfoEmptyDataIcon';
import {navigateToPath} from '../../../../helpers/navigationUtil';
import {IMG_SMALL_URL, whatever} from '../../../../constants';
import {User} from '../../../../types/user';
import {getUser} from '../../../../services/userAuthorization.service';
import {ID} from '@datorama/akita';
import './appToolbar.scss';

interface Prop {
  title: string;
  navigation?: { defHref: string; props: RouteComponentProps; url?: string; data?: whatever };
}

export const AppToolbar: React.FC<Prop> = ({title, navigation}) => {
  const myContext = useContext(notificationContext);
  const history = useHistory();
  const [listNotifications, setListNotifications] = useState<Notifications[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [notificationLength, setNotificationLenght] = useState(0);
  const [owner, setOwner] = useState<User>();

  useEffect(() => {
    getObject<Notifications[]>('notificationList').then((notify: Notifications[]) => {
      setListNotifications(notify);
      if (listNotifications) {
        if (notify && notify.length > 0) {
          const myAux = notify.filter((n) => {
            return n.state === 'OPEN';
          });
          setNotificationLenght(myAux.length);
        }
      }
    });
    // eslint-disable-next-line
  }, [listNotifications]);

  useEffect(() => {
    const fetchOwner = async (): Promise<void> => {
      const ownerResult = await getUser(owner?.id as ID);
      if (ownerResult && ownerResult.status === 200) {
        setOwner(ownerResult.data);
      }
    };
    fetchOwner();
    // eslint-disable-next-line
  }, []);

  const updateNotificationList = (notification: Notifications, index: number): void => {
    const aux: Notifications[] = listNotifications ? listNotifications : notificationTestList;
    if (index) aux[index] = notification;
    setObject('notificationList', aux);
    myContext.notifications = JSON.stringify(aux);
  };

  const deleteNotification = (index: number): void => {
    const aux: Notifications[] = listNotifications;
    aux.splice(index, 1);
    setObject('notificationList', aux);
    myContext.notifications = JSON.stringify(aux);
  };


  return (
    <>
      <IonToolbar className={'appToolbar'}>
        {!navigation ? (
          <IonMenuButton slot="start" />
        ) : (
          <IonButtons slot="start">
            <IonButton
              // defaultHref={navigation.defHref}
              onClick={(): void =>
                navigation.url && !navigation.data ?
                  navigateToPath(navigation.url, navigation.props) :
                  navigation.url && navigation.data ?
                  navigation.props.history.replace({pathname: navigation.url, state: navigation.data}) :
                  navigation.props.history.goBack()
              }
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
        )}
        <IonButtons slot={'end'} className={'buttonsNav'}>
          {/* eslint-disable-next-line max-len */}
          <IonChip style={{background: '#f4f4f4', borderRadius: '11px', width: '43px', height: '38px'}} onClick={() => setShowModal(true)}>
            <IonIcon icon={notifications} style={{fontSize: '20px', margin: 'auto', color: '#313E51'}} />
          </IonChip>
          {notificationLength && notificationLength > 0 ? (
            <IonBadge color={'primary'} style={{fontSize: '12px', position: 'absolute', left: '30px', top: '0px'}}>
              {notificationLength}
            </IonBadge>
          ) : null}
          {/* eslint-disable-next-line max-len */}
          <IonAvatar style={{height: '40px', width: '40px', cursor: 'pointer'}} onClick={(): void => history.push('/tab14')}>
            <IonImg src={owner?.imageUrl ? `${IMG_SMALL_URL}${owner.imageUrl}` : 'assets/icon/avatar-icon.jpg'} />
          </IonAvatar>
        </IonButtons>
        <IonTitle className="animated pulse delay-1s faster titleApp">{title}</IonTitle>
      </IonToolbar>
      <AlertModal
        isOpen={showModal}
        title={'Listado de notificaciones'}
        dissmiss={(value: boolean): void => setShowModal(value)}
        ModalContent={
          listNotifications && listNotifications.length > 0 ? (
            <NotificatonList
              notifiactions={listNotifications}
              getCurrentNotification={updateNotificationList}
              deleteNotification={deleteNotification}
            />
          ) : (
            <EmptyDataIcon message="mensajes recibidos" icon={checkmarkCircleOutline} />
          )
        }
      />
    </>
  );
};
