/* eslint-disable react/prop-types */
import React from 'react';
import {Notifications} from '../../../../types/notifications';
import {IonContent} from '@ionic/react';
import CardNotification from './CardNotification/cardNotification';

interface Prop {
  notifiactions: Notifications[];
  getCurrentNotification: (notification: Notifications, index: number) => void;
  deleteNotification: (index: number) => void;
}

const NotificatonList: React.FC<Prop> = ({notifiactions, getCurrentNotification, deleteNotification}) => {
  return (
    <>
      <IonContent style={{height: '96%'}}>
        <br />
        {notifiactions.map((noti: Notifications, index) => (
          <CardNotification
            key={index}
            notification={noti}
            index={index}
            setNotification={getCurrentNotification}
            dropNotification={deleteNotification}
          />
        ))}
      </IonContent>
    </>
  );
};

export default NotificatonList;
