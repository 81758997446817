/* eslint-disable react/prop-types */
import React from 'react';
import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
} from '@ionic/react';
import {Notifications} from '../../../../../types/notifications';
import moment from 'moment';
import {mail, mailOpen, trash} from 'ionicons/icons';
import {useInitialState} from '../../../../../services/initalStareFC';
import {capturerSlideEvent} from '../../../../../services/general.service';
import {whatever} from '../../../../../constants';
import './cardNotification.scss';

interface Prop {
  notification: Notifications;
  index: number;
  setNotification: (notification: Notifications, index: number) => void;
  dropNotification: (index: number) => void;
}

const CardNotification: React.FC<Prop> = ({notification, index, setNotification, dropNotification}) => {
  const [notify, setNotify] = useInitialState(notification);
  return (
    <>
      <IonItemSliding id={'notificationSlidingList'}>
        <IonItemOptions side={'end'}>
          <IonItemOption
            color="danger"
            onClick={(): whatever => {
              dropNotification(index);
              capturerSlideEvent('notificationSlidingList');
            }}
          >
            <IonIcon slot={'start'} icon={trash} />
          </IonItemOption>
        </IonItemOptions>
        <IonItem
          lines="none"
          className={`notifyContent`}
          color={notify.state === 'OPEN' ? 'light' : 'white'}
          onClick={(): whatever => {
            const aux: Notifications = notify;
            aux.open = !aux.open;
            aux.state = 'COMPLETED';
            setNotify({
              state: aux.state,
              open: aux.open,
              title: aux.title,
              date: aux.date,
              description: aux.description,
            });
            setNotification(aux, index);
          }}
        >
          <IonAvatar slot={'start'} className={`${!notify.open ? 'avatar' : ''}`}>
            <IonIcon
              size="large"
              color={notify.state === 'OPEN' ? 'primary' : 'medium'}
              icon={notify.state === 'OPEN' ? mail : mailOpen}
            />
          </IonAvatar>
          <div className={'notificationBody'}>
            <IonLabel className="titleNotification">{notify.title}</IonLabel>
            <IonNote className={`contentNotification 
            ${notify.open ? 'showContentNotification' : ''}`}>
              {notify.description}
            </IonNote>
            <span className={` ${notify.open ?
                'showSpan' : ''}`}>{moment(new Date(notify.date)).format('LLLL')}</span>
          </div>
        </IonItem>
      </IonItemSliding>
    </>
  );
};

export default CardNotification;
