import {useObservable} from '@mindspace-io/react';
import {employeeFacade} from '../facade/employee.facade';
import {UsersType} from '../../entity/model/users.type';
import {EmployeeRepository} from './repository/employee.repository';
import {ID} from '@datorama/akita';

export function useEmployee(): [EmployeeRepository] {
  const [employees] = useObservable(employeeFacade.emmployees$);
  const [active] = useObservable(employeeFacade.active$);
  const [loading] = useObservable(employeeFacade.loading$);

  const getAllEmployees = (): void => employeeFacade.getEmployees();
  // eslint-disable-next-line max-len
  const updateEmployee = (employeeId: ID, employee: UsersType): void => employeeFacade.updateEmployee(employeeId, employee);
  const createEmployee = (employee: UsersType, invitation: boolean): void =>
    employeeFacade.createEmployee(employee, invitation);

  return [{employees, active, loading, getAllEmployees, updateEmployee, createEmployee}];
}
