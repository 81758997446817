import React, {useState} from 'react';
import {IonIcon} from '@ionic/react';
import './RiskFormImage.scss';
import {whatever} from '../../../constants';
import {trash} from 'ionicons/icons';

interface Prop {
    picture: whatever;
    removeImage: () => void;
}

const RiskFormImage: React.FC<Prop> = ({picture, removeImage}) => {
  const [pic, setPic] = useState(picture);
  const removeImg = (): void => {
    setPic(undefined);
    removeImage();
  };
  return (
    <div className="risk-picture">
      <div onClick={(): void => removeImg()} className="deleteIcon"><IonIcon icon={trash}/></div>
      { picture ? <img src={pic} alt=""/> : ''}
    </div>
  );
};

export default RiskFormImage;
