import React from 'react';
import {Risk} from '../../../types/risk';
import {IonCardContent} from '@ionic/react';
import './riskDisplayer.scss';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {getChanceLevel} from '../../../types/enums/possibilityOfOccurrance';
import {getStorage} from '../../../helpers/localstorageUtil';
import {NOT_CATEGORY, RISK_URL_CATEGORIES, RISK_URL_INHERENT_IMPACT} from '../../../constants';
import {Category} from '../../../types/category';
import {InherentImpactD} from '../../../types/InherentImpact';
import {ID} from '@datorama/akita';


export function filterById<T extends { id: ID }>(id: ID, object: T[]): T {
  return object.find((obj) => obj.id === id) as T;
}

export function filterInherentById<T extends { chanceId: ID }>(id: ID, object: T[]): T {
  return object.find((obj) => obj.chanceId === id) as T;
}

interface ObjectRisk extends RouteComponentProps {
  risk: Risk;
  notNavigate?: boolean;
  longClick?: () => void;
}

const RiskDisplayer: React.FC<ObjectRisk> = (props: ObjectRisk) => {
  let category = props.risk.category;
  let inherentImpact = props.risk.inherentImpact;
  if (typeof category === 'string') {
    category = filterById<Category>(category as string, getStorage(RISK_URL_CATEGORIES)).title;
  }

  if (inherentImpact) {
    inherentImpact = filterInherentById<InherentImpactD>(inherentImpact as ID, getStorage(RISK_URL_INHERENT_IMPACT))
        .description;
  }


  return (
    <>
      <IonCardContent
        className="my-content"
        style={{borderLeft: `4px solid ${props.risk.color}`}}
        onClick={(): void => {
          if (!props.notNavigate) {
            localStorage.setItem('sel-risk', JSON.stringify({dataDetail: props.risk, flag: 'riskDetail'}));
            props.history.push({pathname: '/tab9', state: {dataDetail: props.risk, flag: 'riskDetail'}});
          }
        }}>
        <div style={{color: 'black',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          marginBottom: '5px'}}>
          <div style={{width: '100%', textAlign: 'justify'}}>
            <span className="card-title-risk">{props.risk.description}</span>
          </div>
        </div>
        <div className="risk-card-content" style={{marginTop: '6px'}}>
          <span style={{color: '#0086D1', marginRight: '1em'}}>{category ?? NOT_CATEGORY}</span>
          <span className={'wrapTextRisk'} style={{color: '#0086D1'}}>
            {props.risk.healthImpacts ? props.risk.healthImpacts : inherentImpact ? inherentImpact : 'No impacto'}
            {` - `}
            {!props.risk.inherentChance ?
              props.risk.possibilityOfOccurrance :
              getChanceLevel(props.risk.inherentChance as unknown as string)}
          </span>
        </div>
      </IonCardContent>
    </>
  );
};

export default withRouter(RiskDisplayer);
