import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import ErrorPage404 from './components/dummies/ErrorPage/ErrorPage404';

// Get the root element from the DOM
const container = document.getElementById('root');

// Ensure `container` exists before using it
if (container) {
  const root = ReactDOM.createRoot(container); // Use createRoot
  root.render(
      <React.StrictMode>
        <ErrorPage404>
          <App />
        </ErrorPage404>
      </React.StrictMode>,
  );
}
// Call the element loader after the app has been rendered the first time
void defineCustomElements(window);

