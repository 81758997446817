/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useEffect, useState} from 'react';
import './AppetiteBoard.scss';
import AppetiteBox from '../AppetiteBox/AppetiteBox';
import {RiskAppetite, Appetite, getAppetiteColor} from '../../../../../types/riskAppetite';
import {Impact} from '../../../../../types/impact';
import {PossibilityOfOccurrance, getChanceLevel} from '../../../../../types/enums/possibilityOfOccurrance';
import {IonGrid, IonRow, IonCol} from '@ionic/react';
import {CARD_ANIMATION_CLASS, whatever} from '../../../../../constants';
import {ID} from '@datorama/akita';
import {PossibilityLabels} from '../PossibilityLabels/PossibilityLabels';

interface Props {
    updateColor: whatever;
    impacts: Impact[];
    riskAppetites: RiskAppetite[];
}

const AppetiteBoard: React.FC<Props> = (props)=>{
  const [riskAppetites, setRiskAppetites] = useState<RiskAppetite[]>(props.riskAppetites);


  useEffect(() => {
    // console.log('AppetiteBoard RiskAppetites: ', props.riskAppetites);
    setRiskAppetites(props.riskAppetites);
    // eslint-disable-next-line
  },[props.riskAppetites]);


  const getImpactId = (impactOrder: number): ID => {
    return props.impacts && props.impacts
        .filter((element: whatever) => element.impactOrder === impactOrder)[0].chanceId;
  };

  const updateColor = (riskAppetite: RiskAppetite): void => {
    const result = {
      id: riskAppetite.id,
      impactId: getImpactId(riskAppetite.impactOrder),
      impactOrder: riskAppetite.impactOrder,
      chance: riskAppetite.chance,
      appetite: riskAppetite.appetite,
    };
    props.updateColor(result);
  };

  const getAppetite = (impactOrder: number, chance: whatever): whatever => {
    let obj = null;
    const appetite =
            riskAppetites &&
            riskAppetites.find((appetite: RiskAppetite) =>
              appetite.impactId === getImpactId(impactOrder) && appetite.chance === chance);

    if (appetite) {
      obj = {
        id: appetite.id,
        impactId: appetite.impactId,
        impactOrder: impactOrder,
        chance: getChanceLevel(chance)!,
        appetite: getAppetiteColor(appetite.appetite)!,
      };
    } else {
      obj = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: null!,
        impactId: getImpactId(impactOrder)!,
        impactOrder: impactOrder,
        chance: getChanceLevel(chance)!,
        appetite: Appetite.Initial,
      };
    }
    return obj;
  };

  const renderRow = (impactOrder: number): whatever => {
    return (
      <IonGrid style={{paddingTop: '0px', paddingBottom: '0px'}}>
        <IonRow className={CARD_ANIMATION_CLASS}>
          {Object.keys(PossibilityOfOccurrance).map((chance, index) => (
            <IonCol key={index} size="3">
              <AppetiteBox updateColor={updateColor} riskAppetite={getAppetite(impactOrder, chance)} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    );
  };

  const renderBoard = (): whatever => {
    return (
      <div>
        {props.impacts.map((impact: whatever, index: number) => {
          return (
            <div key={index} className="row-wrapper">
              {/* eslint-disable-next-line max-len */}
              <div className={impact.impactOrder === 0 ? 'divion-border-0' : impact.impactOrder === 1 ? 'divion-border' : impact.impactOrder === 2 ? 'division-border-2' : 'label-wrapper' }>{impact.impactOrder}</div>
              {renderRow(impact.impactOrder)}
            </div>
          );
        }).reverse()}
      </div>
    );
  };

  return (
    <div>
      {
        <IonGrid>
          {renderBoard()}
          <PossibilityLabels />
        </IonGrid>}
    </div>
  );
};
export default AppetiteBoard;
