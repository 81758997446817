
export enum ReviewFrequency {
  YEARLY = 'Anual',
  QUARTELY = 'Trimestral',
  MONTHLY = 'Mensual',
  WEEKLY = 'Semanal',
}

export const reviewFrequencyMap = new Map([
  ['YEARLY', ReviewFrequency.YEARLY],
  ['QUARTELY', ReviewFrequency.QUARTELY],
  ['MONTHLY', ReviewFrequency.MONTHLY],
  ['WEEKLY', ReviewFrequency.WEEKLY],
]);

const reverseReviewFrequency = new Map([
  [ReviewFrequency.YEARLY, 'YEARLY'],
  [ReviewFrequency.QUARTELY, 'QUARTELY'],
  [ReviewFrequency.MONTHLY, 'MONTHLY'],
  [ReviewFrequency.WEEKLY, 'WEEKLY'],
]);

export const getReviewKey = (key: ReviewFrequency) => reverseReviewFrequency.get(key);
export const getReviewValue = (key: string) => reviewFrequencyMap.get(key);
