/* eslint-disable react/prop-types */
import {IonToast} from '@ionic/react';
import {MINUTE, whatever} from '../../../../constants';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDeleteRisk?: (willDelete: boolean) => void;
}

export const DeleteRiskToast: React.FC<Props> = ({isOpen, onClose, onDeleteRisk}) => {
  return (
    <IonToast
      isOpen={isOpen}
      onDidDismiss={(): whatever => onClose()}
      message="¿Desea eliminar este riesgo?"
      color={'dark'}
      position={'bottom'}
      duration={MINUTE}
      animated={true}
      buttons={[
        {
          side: 'end',
          text: 'Si',
          handler: (): void => {
            if (onDeleteRisk) onDeleteRisk(true);
          },
        },
        {
          side: 'end',
          text: 'No',
        },
      ]}
    />
  );
};
