
import {Observable, Subscription} from 'rxjs';
import {riskQuery, RiskQuery} from '../query/risk.query';
import {Risk} from '../../types/risk';
import {riskService, RiskService} from '../../services/risk.service';
import {ID} from '@datorama/akita';
import {whatever} from '../../constants';
import {Media as RiskPhotos} from '../../types/media';

export class RiskFacade {
  public readonly risk$ = this.riskQuery.risk$;
  public readonly activeRisk$ = this.riskQuery.activeRisk$ as Observable<Risk>;
  private readonly subscriptions = new Subscription();

  constructor(private riskQuery: RiskQuery, private riskService: RiskService) {}

  public getRisks(id?: ID, limit?: string): void {
    this.subscriptions.add(this.riskService.getRisks(id, limit).subscribe());
  }

  public getRiskCategory(storage?: boolean): void {
    this.subscriptions.add(this.riskService.getRiskCategory(storage).subscribe());
  }

  public getRiskInherentImpact(storage?: boolean): void {
    this.subscriptions.add(this.riskService.getRiskInherentImpact(storage).subscribe());
  }

  public getRiskPossibleEffect(storage?: boolean): void {
    this.subscriptions.add(this.riskService.getRiskPossibleEffect(storage).subscribe());
  }
  public getGeneral(API: string, storage?: boolean): void {
    this.subscriptions.add(this.riskService.getGeneral(API, storage).subscribe());
  }
  public getRiskAppetites(storage?: boolean): void {
    this.subscriptions.add(this.riskService.getRiskAppetites( storage).subscribe());
  }
  public saveRiskAppetites(data: whatever): void {
    this.subscriptions.add(this.riskService.saveRiskAppetites( data).subscribe());
  }

  public postGeneral(API: string, data: whatever): void {
    this.subscriptions.add(this.riskService.postGeneral(API, data).subscribe());
  }

  public updateRisk(data: whatever, id: ID): void {
    this.subscriptions.add(this.riskService.updateRisk(data, id).subscribe());
  }
  public saveRisk(data: whatever): void {
    this.subscriptions.add(this.riskService.saveRisk(data).subscribe());
  }
  public addControlToRisk(riskId: ID, controlId: ID): void {
    this.subscriptions.add(this.riskService.addControlToRisk(riskId, controlId).subscribe());
  }
  public removeControlToRisk(riskId: ID, controlId: ID): void {
    this.subscriptions.add(this.riskService.removeControlToRisk(riskId, controlId).subscribe());
  }
  public removeRisk(riskId: ID): void {
    this.subscriptions.add(this.riskService.removeRisk(riskId).subscribe());
  }

  public addPhotos(riskId: ID, data: RiskPhotos[]): void {
    this.subscriptions.add(this.riskService.addPhotos(riskId, data).subscribe());
  }

  public handleGetRiskList(): void {
    this.subscriptions.add(this.riskService.handleGetRiskList().subscribe());
  }

  public setActive(riskId: ID | null): void {
    this.riskService.setActive(riskId);
  }

  public removeActive(): void {
    this.riskService.removeActive();
  }
  public killSubscription(): void {
    this.subscriptions.unsubscribe();
  }

  public updateActive(newState: Risk): void {
    this.riskService.updateActive(newState);
  }
}

export const riskFecade = new RiskFacade(riskQuery, riskService);

